import { useEffect, useState } from "react";
import { apenasNumeros } from "@/services/utils";
import { useFormik } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ptBR } from "date-fns/locale";
import { setInfoDialog } from "@/store/reducers/geral";
import { useDispatch } from "react-redux";
import { Container, Paper } from "@mui/material";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import BotaoPrincipal from "@/components/BotaoPrincipal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Logo from "@/components/Logo";
import TituloTelaAcao from "@/components/TituloTelaAcao";
import CamposInvalidos from "@/components/CamposInvalidos";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router";
import { get, post } from "../../../../../services/apiCreditoPessoal";
import InputCep from "../../../../../components/InputCep";
import LoadingWithProgress from "../../../../../components/LoadingWithProgress";

export default function PreAnaliseComCadastro() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [carregar, setCarregar] = useState(false);
  const { id, token } = useParams();
  const [progress, setProgress] = useState(10);
  const [messageProgress, setMessageProgress] = useState("");

  const [incrementProgress, setIncrementProgress] = useState(false);
  const [activeLoading, setActiveLoading] = useState(false);

  const Schema = Yup.object().shape({
    birthDate: Yup.date().required("Informe a data de nascimento"),
    professionalClass: Yup.string().required("Informe a profissão"),
    gender: Yup.string().required("Informe o gênero"),
    salary: Yup.string().required("Informe a renda"),
    zipCode: Yup.string().required("Informe o CEP"),
  });

  const formik = useFormik({
    initialValues: {
      birthDate: null,
      professionalClass: "",
      salary: "",
      gender: "",
      zipCode: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      enviarDados(values);
    },
  });
  const { setFieldValue } = formik;

  const enviarDados = async (values) => {
    try {
      values.zipCode = apenasNumeros(values.zipCode);
      values.idPersonalCredit = id;
      values.token = token;
      setActiveLoading(true);
      setMessageProgress(`Executando análise de crédito, por favor aguarde...`);

      const result = await post("public/pre-analysys", values);
      setActiveLoading(false);

      if (result.error) {
        dispatch(setInfoDialog({ open: true, descricao: result.error }));
        return;
      }
      navigation(
        `/app/credito-pessoal/aguardando-analise/${result.idPersonalCredit}/${result.token}`
      );
    } catch (e) {
      console.log(e);
      setActiveLoading(false);
      dispatch(
        setInfoDialog({
          open: true,
          descricao:
            "Ocorreu um erro ao processar. Tente novamente em instantes.",
        })
      );
    }
  };

  useEffect(() => {
    setCarregar(true);
  }, []);

  useEffect(() => {
    const carregaDados = async () => {
      try {
        setActiveLoading(true);
        setMessageProgress(`Buscando dados, por favor aguarde...`);
        const result = await get(`public/personal-credit/${id}/${token}`);
        setActiveLoading(false);
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        setFieldValue("zipCode", result.zipCode);
      } catch (e) {
        console.log(e);
        setActiveLoading(false);
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao consultar seus dados. Por favor atualize a página!",
          })
        );
      }
    };

    if (carregar && id && token) {
      setCarregar(false);
      carregaDados();
    }
  }, [carregar, id, token, dispatch, setFieldValue]);

  useEffect(() => {
    if (activeLoading) {
      const interval = setInterval(() => {
        setIncrementProgress(true);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [activeLoading]);

  useEffect(() => {
    if (incrementProgress) {
      setIncrementProgress(false);
      if (progress < 95) {
        setProgress(progress + 5);
      }
    }
  }, [incrementProgress, progress]);

  return (
    <Container maxWidth="md" fullWidth>
      <Paper sx={{ p: 2, my: 4 }}>
        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}>
                <Logo></Logo>
              </Grid>

              <Grid item xs={12} sx={{ mb: 2 }}>
                <TituloTelaAcao>
                  Precisamos de mais algumas informações para continuar
                </TituloTelaAcao>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="select-label-professionalClass">
                    Classe Profissional *
                  </InputLabel>
                  <Select
                    labelId="select-label-professionalClass"
                    label="Grau de instrução *"
                    id="select-label-professionalClass"
                    value={formik.values.professionalClass}
                    error={
                      formik.touched.professionalClass &&
                      Boolean(formik.errors.professionalClass)
                    }
                    variant="outlined"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "professionalClass",
                        event.target.value
                      );
                    }}>
                    <MenuItem value="ASSALARIADO">ASSALARIADO</MenuItem>
                    <MenuItem value="APOSENTADO">APOSENTADO</MenuItem>
                    <MenuItem value="AGRICULTOR">AGRICULTOR</MenuItem>
                    <MenuItem value="SERVIDOR_PUBLICO">
                      SERVIDOR PÚBLICO
                    </MenuItem>
                    <MenuItem value="PROFISSIONAL_LIBERAL">
                      PROFISSIONAL LIBERAL
                    </MenuItem>
                    <MenuItem value="AUTONOMO">AUTÔNOMO</MenuItem>
                    <MenuItem value="EMPRESARIO">EMPRESÁRIO</MenuItem>
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: "red" }}>
                  {formik.touched.professionalClass &&
                    formik.errors.professionalClass}
                </FormHelperText>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  name="salary"
                  label="Renda *"
                  fullWidth
                  variant="outlined"
                  value={formik.values.salary}
                  onChange={formik.handleChange}
                  error={formik.touched.salary && Boolean(formik.errors.salary)}
                  helperText={formik.touched.salary && formik.errors.salary}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="select-label-gender">Gênero *</InputLabel>
                  <Select
                    labelId="select-label-gender"
                    label="Gênero *"
                    value={formik.values.gender}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                    variant="outlined"
                    onChange={(event) => {
                      formik.setFieldValue("gender", event.target.value);
                    }}>
                    <MenuItem value="MASCULINO">MASCULINO</MenuItem>
                    <MenuItem value="FEMININO">FEMININO</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3.5}>
                <DesktopDatePicker
                  disableFuture
                  label="Data de nascimento *"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.birthDate || ""}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("birthDate", new Date(value));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={
                        formik.touched.birthDate &&
                        Boolean(formik.errors.birthDate)
                      }
                      helperText={
                        formik.touched.birthDate && formik.errors.birthDate
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2.5}>
                <InputCep {...{ formik }} />
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent="center">
              <CamposInvalidos {...{ formik }} />

              <Grid container spacing={3} sx={{ mt: 1, ml: 1 }}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <BotaoPrincipal
                      endIcon={<ArrowForwardIcon />}
                      onClick={() => {
                        formik.handleSubmit();
                      }}>
                      AVANÇAR
                    </BotaoPrincipal>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </LocalizationProvider>
        <LoadingWithProgress
          {...{ activeLoading, progress, messageProgress }}
        />
      </Paper>
    </Container>
  );
}
