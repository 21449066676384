import axios from "axios";

export function postExterno(endpoint, body) {
  var promise = new Promise((resolve, reject) => {
    if (!body) {
      body = {};
    }
    axios
      .post(endpoint, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
}

export function getExterno(endpoint, headers) {
  var promise = new Promise((resolve, reject) => {
    axios
      .get(endpoint, headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
}

export function remove(endpoint) {
  var promise = new Promise((resolve, reject) => {
    axios
      .delete(endpoint)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
}
