import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
// third-party
import { Provider as ReduxProvider } from "react-redux";

// google auth
import { GoogleOAuthProvider } from "@react-oauth/google";

// project import
import App from "./App";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import "./global.scss";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { createRoot } from "react-dom/client";
const root = createRoot(document.getElementById("root"));
let persistor = persistStore(store);
root.render(
  // clientId
  // link para passo a passo
  // https://developers.google.com/identity/gsi/web/guides/get-google-api-clientid?hl=pt-br
  <GoogleOAuthProvider clientId="298303181223-jibvbrmv3novu7c9d47dod5ln3871pth.apps.googleusercontent.com">
    <StrictMode>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename="/">
            <App />
          </BrowserRouter>
        </PersistGate>
      </ReduxProvider>
    </StrictMode>
  </GoogleOAuthProvider>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
