import Typography from "@mui/material/Typography";
export default function TituloTelaAcao(props) {
  return (
    <Typography
      color="primary"
      sx={{
        fontSize: { xs: 18, md: 20 },
        fontWeight: "600",
        textAlign: "center",
      }}>
      {props.children}
    </Typography>
  );
}
