import { ehProducao } from "@/services/ambiente";
import { getContextTypeUser } from "@/services/ambiente";
import { sleep } from "../../../services/utils";
import { ehAreaParceiro } from "../../../services/ambiente";

export async function getTokenIugu(values) {
  const positionSpace = values.cardName.indexOf(" ");

  try {
    await window.Iugu.setAccountID("05561B2824B949098506A4226E94B970");
    await window.Iugu.setTestMode(!ehProducao());
    const name = values.cardName.substring(0, positionSpace).trim();
    const lastName = values.cardName.substring(positionSpace, 999).trim();
    const creditCard = window.Iugu.CreditCard(
      values.cardNumber.toString(),
      values.expireDate.substring(0, 2),
      values.expireDate.substring(2, 6),
      name,
      lastName,
      values.ccv
    );
    if (!creditCard.valid()) {
      console.log(creditCard.errors);
    }
    var promise = new Promise(async (resolve, reject) => {
      window.Iugu.createPaymentToken(creditCard, function (response) {
        if (response.errors) {
          console.log(response.errors);
          reject(response.errors);
        } else {
          resolve(response.id);
        }
      });
    });
    return await promise;
  } catch (e) {
    console.log(e);
    return "";
  }
}

export async function retornaRotaParaErro(idRequest, token, gateway) {
  if (gateway) {
    return `${getContextTypeUser()}/pagamento/erro/${idRequest}/${token}/${gateway}`;
  }
  return `${getContextTypeUser()}/pagamento/erro/${idRequest}/${token}`;
}

export async function retornaRotaSucesso(
  resultadoPagamento,
  idRequest,
  token,
  stepConfirmation
) {
  if (
    !ehAreaParceiro() &&
    !stepConfirmation &&
    !resultadoPagamento.authorized3ds &&
    resultadoPagamento.enableRefundPaymentClient
  ) {
    return `${getContextTypeUser()}/confirma-contratacao/${idRequest}/${token}`;
  }
  if (
    !resultadoPagamento.requireFaceMatch &&
    !resultadoPagamento.requireContract
  ) {
    return `${getContextTypeUser()}/operacao-finalizada`;
  }

  if (resultadoPagamento.requireFaceMatch) {
    return `${getContextTypeUser()}/biometria/${idRequest}/${token}`;
  }
  if (!stepConfirmation) {
    await sleep(4000);
  }
  return `${getContextTypeUser()}/confirmacao/${idRequest}/${token}`;
}
