export const formataCpfCnpj = (valor) => {
  if (!valor) {
    return "";
  }

  valor = valor.replace(/\D/g, ""); // remove todos os caracteres não numéricos

  if (valor.length <= 11) {
    valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
    valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
    valor = valor.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    valor = valor.replace(/(\d{2})(\d)/, "$1.$2");
    valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
    valor = valor.replace(/(\d{3})(\d)/, "$1/$2");
    valor = valor.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
  }

  return valor;
};

export const validaCpfCnpj = (valor) => {
  valor = valor || "";
  valor = valor.replace(/\D/g, ""); // remove todos os caracteres não numéricos
  if (valor.length === 11) {
    let soma = 0;
    let resto;

    if (
      valor === "00000000000" ||
      valor === "11111111111" ||
      valor === "22222222222" ||
      valor === "33333333333" ||
      valor === "44444444444" ||
      valor === "55555555555" ||
      valor === "66666666666" ||
      valor === "77777777777" ||
      valor === "88888888888" ||
      valor === "99999999999"
    ) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(valor.substring(i - 1, i)) * (11 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(valor.substring(9, 10))) {
      return false;
    }

    soma = 0;

    for (let i = 1; i <= 10; i++) {
      soma += parseInt(valor.substring(i - 1, i)) * (12 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(valor.substring(10, 11))) {
      return false;
    }

    return true;
  } else if (valor.length === 14) {
    const cnpj = valor;
    return validarCNPJ(cnpj);
  }
};
export const validarCNPJ = (cnpj) => {
  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(1)) return false;
  return true;
};

export const ocultarDocumento = (valor) => {
  if (!valor) {
    return "";
  }

  const documento = formataCpfCnpj(valor);

  if (valor.length === 11) {
    const asterisco = "*";
    const numeros = documento.substring(3, 12);

    return asterisco.repeat(3) + numeros + asterisco.repeat(2);
  } else if (valor.length === 14) {
    const asterisco = "*";
    const numeros = documento.substring(2, 16);

    return asterisco.repeat(2) + numeros + asterisco.repeat(2);
  }
};

export const retornaTipoDocumento = (documento) => {
  let tipoDocumento = "";
  if (documento.length === 11) {
    tipoDocumento = "CPF";
  } else {
    tipoDocumento = "CNPJ";
  }

  return tipoDocumento;
};
