import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limiteAprovado: "",
  dadosAnaliseCompleta: {},
};

const creditoPessoal = createSlice({
  name: "creditoPessoal",
  initialState,
  reducers: {
    setDadosAnaliseCompleta(state, action) {
      state.dadosAnaliseCompleta = action.payload;
    },
    setLimiteAprovado(state, action) {
      state.limiteAprovado = action.payload;
    },
    resetCreditoPessoal(state) {
      state.limiteAprovado = initialState.limiteAprovado;
      state.dadosAnaliseCompleta = initialState.dadosAnaliseCompleta;
    },
  },
});

export default creditoPessoal.reducer;

export const {
  setLimiteAprovado,
  setDadosAnaliseCompleta,
  resetCreditoPessoal,
} = creditoPessoal.actions;
