import { retornaUrlServer } from "@/services/apiParceiro";

export function ehLocal() {
  const url = retornaUrlServer();
  return url.indexOf("localhost") !== -1;
}
export function ehHomologacao() {
  return process.env.REACT_APP_ENVIRONMENT === "HOMOLOG";
}
export function ehProducao() {
  return process.env.NODE_ENV === "production";
}

export function retornaUrlApp() {
  if (ehLocal()) {
    return "http://localhost:3001/";
  }
  if (ehHomologacao()) {
    return "https://app.homologacao.quitapay.com/";
  }
  return "https://app.quitapay.com/";
}

export function retornaUrlHelp() {
  if (ehProducao() || ehHomologacao()) {
    return "https://ajuda.quitaboletos.com.br";
  }

  return "http://localhost:3000";
}

export function redirecionaParaSite() {
  window.location.href = retornaUrlSite();
}

export function redirecionaSiteQuitaboletos() {
  window.location.href = retornaUrlSiteQuitaboletos();
}

export function retornaUrlSiteQuitaboletos() {
  if (ehLocal()) {
    return "http://localhost:3001/";
  }
  if (ehHomologacao()) {
    return "https://site.homologacao.quitaboletos.com/";
  }
  return "https://quitaboletos.com.br/";
}

export function retornaUrlSite() {
  if (ehLocal()) {
    return "http://localhost:3001/";
  }
  if (ehHomologacao()) {
    return "https://app.homologacao.quitapay.com/";
  }
  return "https://quitapay.com/";
}

export const getContextTypeUser = () => {
  if (window.location.href.indexOf("dashboard") !== -1) {
    return "/dashboard";
  } else if (window.location.href.indexOf("area-cliente") !== -1) {
    return `/app/area-cliente`;
  }
  return `/app`;
};

export const ehAreaLogadaCliente = () => {
  return window.location.href.indexOf("area-cliente") !== -1;
};

export const getArea = () => {
  if (
    window.location.href.indexOf("dashboard") !== -1 ||
    window.location.href.indexOf("parceiro") !== -1
  ) {
    return "parceiro";
  }
  return `cliente`;
};
export const ehAreaParceiro = () => {
  return getArea() === "parceiro";
};
export const ehAreaCliente = () => {
  return getArea() === "cliente";
};

export function appendHeaderScript(script) {
  try {
    const tagScript = document.createElement("script");
    tagScript.type = "text/javascript";
    if (script.type === "text") {
      tagScript.text = script.script;
    } else {
      tagScript.src = script.script;
    }
    document.head.appendChild(tagScript);
  } catch (e) {
    console.log(e);
  }
}

export function isQuitaboletos() {
  return window.location.href.indexOf("quitaboletos.com") !== -1;
}

export function retornaRotaPagamento(infoPayment) {
  if (infoPayment.try3ds) {
    return `${getContextTypeUser()}/pagamento-ep/${infoPayment.idRequest}/${
      infoPayment.token
    }`;
  }
  return `${getContextTypeUser()}/pagamento/${infoPayment.idRequest}/${
    infoPayment.token
  }`;
}
