// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  openItem: ["dashboard"],
  openComponent: "buttons",
  drawerOpen: false,
  componentDrawerOpen: true,
};

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },
    resetMenu(state) {
      state.openItem = initialState.openItem;
      state.openComponent = initialState.openComponent;
      state.drawerOpen = initialState.drawerOpen;
      state.componentDrawerOpen = initialState.componentDrawerOpen;
    },
  },
});

export default menu.reducer;

export const {
  activeItem,
  activeComponent,
  openDrawer,
  resetMenu,
  openComponentDrawer,
} = menu.actions;
