import axios from "axios";
import { retornaUrlBase, getCompanyBindParam } from "@/services/api";

async function perdaAutorizacao() {
  alert("Por favor, faça login novamente!");
  sessionStorage.removeItem("dadosUsuarioLogado");
  window.location.href = "/app/login";
}

export async function getTokenReport() {
  const result = await get(`secure/report/token`);
  return result.token;
}

export function retornaUsuario(login) {
  let usuarioLogadoStorage = {};
  if (!login && !usuarioLogadoStorage) {
    perdaAutorizacao();
    return;
  }
  return usuarioLogadoStorage;
}

export function retornaUsuarioLogado() {
  let usuarioLogado = {};
  try {
    const dadosUsuario = localStorage.getItem("dadosUsuarioLogado");
    if (!dadosUsuario || dadosUsuario === "null") {
      usuarioLogado = {};
    } else {
      usuarioLogado = JSON.parse(dadosUsuario);
    }
  } catch (e) {}
  return usuarioLogado;
}

export function retornaAuthorization() {
  let usuarioLogado = retornaUsuarioLogado();
  return "Bearer " + (usuarioLogado.userToken || "");
}

export async function abrirRelatorio(urlRelatorio) {
  let url = retornaUrlServer() + `${urlRelatorio}`;
  const tokenReport = await getTokenReport();
  if (url.indexOf("?") === -1) {
    url += "?tokenReport=";
  } else {
    url += "&tokenReport=";
  }
  url += tokenReport;
  window.open(url, "_blank");
}

function retornaHeaders() {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: retornaAuthorization(),
    },
  };
}

export function retornaUrlServer() {
  let url = retornaUrlBase();

  return url + "internal-partner/";
}

export function post(endpoint, body) {
  var promise = new Promise((resolve, reject) => {
    if (!body) {
      body = {};
    }
    endpoint += getCompanyBindParam(endpoint);
    axios
      .post(retornaUrlServer() + endpoint, body, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

export function put(endpoint, body) {
  var promise = new Promise((resolve, reject) => {
    if (!body) {
      body = {};
    }

    endpoint += getCompanyBindParam(endpoint);
    axios
      .put(retornaUrlServer() + endpoint, body, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}
const trataErro = (error, reject) => {
  if (error.response && error.response.status === 401) {
    perdaAutorizacao();
    return;
  }
  reject(error);
};

export function get(endpoint, params) {
  var promise = new Promise((resolve, reject) => {
    const config = retornaHeaders();
    config.params = params;
    endpoint += getCompanyBindParam(endpoint);
    axios
      .get(retornaUrlServer() + endpoint, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

export function remove(endpoint) {
  var promise = new Promise((resolve, reject) => {
    endpoint += getCompanyBindParam(endpoint);
    axios
      .delete(retornaUrlServer() + endpoint, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}
