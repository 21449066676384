import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { getPrimaryColorButton } from "@/services/whitelabel";
export default function BotaoPrincipal(props) {
  const user = useSelector((state) => state.user);
  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: getPrimaryColorButton(user),
        color: user.isQuitaboletos ? "#D8FF57" : "#fff",
      }}
      {...props}>
      {props.children}
    </Button>
  );
}
