import { Outlet } from "react-router-dom";
import Spinner from "@/components/Spinner";
import Toaster from "@/components/Toaster";
import Dialog from "@/components/Dialog";
import CssBaseline from "@mui/material/CssBaseline";
import NavBar from "@/components/navbarparceiro";
import { ThemeProvider } from "@mui/material/styles";
import DialogConfirmacao from "@/components/DialogConfirmacao";
import { getPrimaryColorButton } from "@/services/whitelabel";
import { useSelector } from "react-redux";
import {
  setInfoDialog,
  setInfoDialogConfirmacao,
} from "@/store/reducers/geral";
import { createTheme } from "@mui/material/styles";
// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const geral = useSelector((state) => state.geral);
  const user = useSelector((state) => state.user);

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          background: {
            default: "#fafaf3",
          },
          primary: {
            light: getPrimaryColorButton(user),
            main: getPrimaryColorButton(user),
            dark: getPrimaryColorButton(user),
            contrastText: "#ffffff",
          },
        },
      })}>
      <NavBar />
      <CssBaseline />
      <Outlet />
      {geral?.infoDialog?.open && (
        <Dialog {...{ infoDialog: geral.infoDialog, setInfoDialog }} />
      )}
      <Spinner {...{ showSpinner: geral.showSpinner }}></Spinner>
      <Toaster {...{ infoToaster: geral.infoToaster }}></Toaster>
      {geral?.infoDialogConfirmacao.open && (
        <DialogConfirmacao
          {...{
            infoDialogConfirmacao: geral.infoDialogConfirmacao,
            setInfoDialogConfirmacao,
          }}
        />
      )}
    </ThemeProvider>
  );
};

export default MinimalLayout;
