import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ehAreaParceiro, ehProducao } from "./services/ambiente";
import { useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { getFavicon, getUrlLogo } from "./services/whitelabel";
import { registerMetadata } from "./services/metadataEvent";
import { get } from "@/services/api";
import { generateDeviceId } from "./services/deviceId";
import { createChat } from "@n8n/chat";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  setCoresPadroes,
  setIsQuitaboletos,
  setLogos,
  setIdCompanyBind,
  setKeepQuitaLogo,
  setWhatsappSuport,
  resetWhitelabel,
  setUrlLogo,
} from "@/store/reducers/user";
import {
  adicionaScriptsHeader,
  adicionaScriptsQuita,
} from "./services/scriptHeader";
import GoogleRecaptcha from "./GoogleRecaptcha";
import Routes from "./routes";
import ThemeCustomization from "./themes";
import ScrollTop from "./components/ScrollTop";
import Favicon from "react-favicon";
import "@n8n/chat/style.css";

const App = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [carregar, setCarregar] = useState(false);
  const [faviconUrl, setFaviconUrl] = useState();

  useEffect(() => {
    setCarregar(true);
  }, []);

  useEffect(() => {
    const setDefaultContext = () => {
      document.title = "QuitaPay";
      dispatch(resetWhitelabel());
      setFaviconUrl(getFavicon());
      dispatch(setUrlLogo(getUrlLogo()));
      if (ehProducao()) {
        adicionaScriptsQuita();
      }
    };
    const registerMetadataSimulator = () => {
      const metaData = {};
      metaData.utmSource = searchParams.get("utm_source");
      metaData.utmMedium = searchParams.get("utm_medium");
      metaData.utmCampaign = searchParams.get("utm_campaign");
      metaData.utmId = searchParams.get("utm_id");
      metaData.utmTerm = searchParams.get("utm_term");
      metaData.idMetadataRef =
        sessionStorage.getItem("idMetadataMarketing") || "";
      metaData.isMobile = isMobile;
      metaData.userAction = "OPEN_APP";
      metaData.url = window.location.href;
      registerMetadata(metaData);
    };

    const loadContext = () => {
      dispatch(setIsQuitaboletos(false));
      const parsedURL = new URL(window.location.href);
      const host = parsedURL.hostname;
      const subdomain = host.split(".")[0];

      if (!ehProducao()) {
        setDefaultContext();
        // loadPartnerInfoQuitaBoletosApp();
        return;
      }

      const isPwaAccess =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone === true;

      const dominioQuitapay = host.indexOf("quitapay.com") !== -1;
      const dominioQuitaboletos = host.indexOf("quitaboletos.com.br") !== -1;
      if (!dominioQuitapay && !dominioQuitaboletos) {
        loadPartnerInfo(host);
        return;
      }
      if (host === "app.quitaboletos.com.br" || isPwaAccess) {
        adicionaScriptsQuita();
        loadPartnerInfoQuitaBoletosApp();
        return;
      }
      if (subdomain !== "app") {
        loadPartnerInfo(subdomain);
        return;
      }
      setDefaultContext();
    };

    const loadPartnerInfoQuitaBoletosApp = () => {
      document.title = "QuitaBoletos";
      dispatch(setIsQuitaboletos(true));
      dispatch(
        setCoresPadroes({
          primaryColorButton: "#145401",
          lightColorButton: "#f0fce3",
          topBarColor: "#2e312e",
        })
      );
      dispatch(
        setLogos({
          favicon: "/logo.png",
          lightBackground: "/logo.png",
          darkBackground: "/logo_branco.png",
        })
      );
      dispatch(setKeepQuitaLogo(false));
      dispatch(setUrlLogo("http://quitaboletos.com.br/"));
      setFaviconUrl("/logo.png");

      if (ehProducao() && !ehAreaParceiro()) {
        createChat({
          webhookUrl:
            "https://n8n.quitabank.com.br/webhook/37be03f8-bbb9-492f-9c9d-ef24181089cc/chat",
          defaultLanguage: "ptBR",
          initialMessages: ["Olá, como posso ajudar?"],
          i18n: {
            ptBR: {
              title: "Tire suas dúvidas",
              subtitle: "",
              footer: "",
              getStarted: "Nova conversa",
              inputPlaceholder: "Escreva aqui sua dúvida",
            },
          },
        });
      }
    };

    const loadPartnerInfo = async (context) => {
      try {
        dispatch(activeSpinner());
        const url = `public/info-whitelabel-partner/${context}/`;
        const result = await get(url);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(
            setInfoDialog({
              open: true,
              descricao: result.error,
            })
          );
          return;
        }
        document.title = result.partnerName;
        dispatch(setCoresPadroes(result.colors));
        dispatch(setLogos(result.logos));
        dispatch(setKeepQuitaLogo(result.keepQuitaLogo));
        dispatch(setWhatsappSuport(result.whatsappSuport));
        dispatch(setUrlLogo(result.urlToLogo || getUrlLogo()));
        dispatch(setIdCompanyBind(result.idCompanyBind));
        sessionStorage.setItem("idCompanyBindWhitelabel", result.idCompanyBind);
        const scripts = result.script_header;
        adicionaScriptsHeader(scripts);
        setFaviconUrl(result.logos.favicon);
      } catch (e) {
        setDefaultContext();
        dispatch(cancelSpinner());
        console.log(e);
      }
    };

    if (carregar) {
      dispatch(cancelSpinner());
      setCarregar(false);
      const idMetadata = searchParams.get("idMetadata");
      if (idMetadata) {
        sessionStorage.setItem("idMetadataMarketing", idMetadata);
      }
      registerMetadataSimulator();
      loadContext();

      generateDeviceId();
      ChartJS.register(
        CategoryScale,
        LinearScale,
        ArcElement,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
      );
    }
  }, [carregar, searchParams, dispatch]);

  return (
    <GoogleRecaptcha>
      <ThemeCustomization>
        {faviconUrl && <Favicon url={faviconUrl} />}
        <ScrollTop>
          <Routes />
        </ScrollTop>
      </ThemeCustomization>
    </GoogleRecaptcha>
  );
};

export default App;
