import { redirecionaSiteQuitaboletos } from "@/services/ambiente";
import { Container, Grid, Paper, Typography } from "@mui/material";
import BotaoText from "@/components/BotaoText";
import Logo from "../../../../../components/Logo";
import BotaoPrincipal from "../../../../../components/BotaoPrincipal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { get } from "../../../../../services/apiCreditoPessoal";
import IconSad from "../../../../../components/icons/IconSad";
import { retornaUrlApp } from "../../../../../services/ambiente";

export default function PreAnaliseFinalizada() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { id, token } = useParams();
  const [carregar, setCarregar] = useState(false);
  const [preAnalise, setPreAnalise] = useState({});

  useEffect(() => {
    const verifyPersonalCredit = async () => {
      try {
        dispatch(activeSpinner());
        const result = await get(`public/status-pre-analysis/${id}/${token}`);
        dispatch(cancelSpinner());

        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        if (!result.finished) {
          navigation(
            `/app/credito-pessoal/aguardando-analise/${result.idPersonalCredit}/${result.token}`
          );
        }
        setPreAnalise(result);
      } catch (e) {
        dispatch(cancelSpinner());
        console.log(e);
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Não foi possível buscar o status da proposta. Por favor atualize a página ou entre em contato com o suporte!",
          })
        );
      }
    };
    if (carregar && id && token) {
      setCarregar(false);
      verifyPersonalCredit();
    }
  }, [dispatch, id, token, carregar, navigation]);

  useEffect(() => {
    setCarregar(true);
  }, []);

  const contatarWhatsapp = () => {
    const message = `Olá! Vim da Quita Boletos! Meu código de atendimento é 124811 e minha proposta é ${preAnalise.externalProposal}`;
    window.location.href = `https://wa.me/558006480047?text=${message}`;
  };
  return (
    <Container maxWidth="sm" fullWidth>
      <Paper sx={{ p: 2, my: 4 }}>
        <Grid container spacing={2}>
          {preAnalise.finished && (
            <>
              {preAnalise.isApproved && (
                <>
                  <Grid item xs={12} align="center" mb={2}>
                    <Logo style={{ maxWidth: "155px" }} />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Typography variant="h6">
                      Sua pré-análise foi <b>APROVADA</b> com sucesso!
                    </Typography>
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Typography>
                      Agora precisamos que entre em contato com nosso parceiro
                      para continuar a simulação e contratação do crédito.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} align="center" mt={1}>
                    <BotaoPrincipal onClick={contatarWhatsapp}>
                      CLIQUE AQUI PARA CONTINUAR
                    </BotaoPrincipal>
                  </Grid>
                </>
              )}
              {!preAnalise.isApproved && (
                <>
                  <Grid item xs={12} align="center">
                    <IconSad
                      fill="#104501"
                      style={{ height: "180px" }}></IconSad>
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Typography variant="h6">
                      Não foi possível encontrar propostas de crédito no
                      momento.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Typography>
                      Por favor, tente novamente mais tarde ou realize a
                      contratação utilizando seu cartão de crédito!
                    </Typography>
                  </Grid>
                </>
              )}
            </>
          )}
          {!preAnalise.isApproved && (
            <Grid item xs={12} align="center" mt={2}>
              <BotaoText
                onClick={() => {
                  window.location.href = `${retornaUrlApp()}app/simulacao`;
                }}>
                UTILIZAR MEU CARTÃO DE CRÉDITO PARA CONTRATAR
              </BotaoText>
            </Grid>
          )}
          <Grid item xs={12} align="center">
            <BotaoText
              onClick={() => {
                window.location.href = redirecionaSiteQuitaboletos();
              }}>
              VOLTAR AO SITE
            </BotaoText>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
