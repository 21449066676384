import InputMask from "react-input-mask";
import { activeSpinner, cancelSpinner } from "../store/reducers/geral";
import { useDispatch } from "react-redux";
import { getExterno } from "../services/apiExterna";
import { TextField } from "@mui/material";

export default function InputCep({ formik }) {
  const dispatch = useDispatch();

  const handleChangeCep = async (value) => {
    formik.setFieldValue("zipCode", value);
    const zipCode = (value || "").replace(/\D/g, "");
    if (zipCode.length !== 8 || formik.values.street) {
      return;
    }
    dispatch(activeSpinner());
    try {
      const url = `https://viacep.com.br/ws/${zipCode}/json/`;
      let request = await getExterno(url);
      dispatch(cancelSpinner());
      if (request && request.logradouro) {
        formik.setFieldValue("neighborhood", request.bairro);
        formik.setFieldValue("street", request.logradouro);
        formik.setFieldValue("city", request.localidade);
        formik.setFieldValue("province", request.uf);
      }
    } catch (e) {
      console.log(e);
      dispatch(cancelSpinner());
    }
  };

  return (
    <>
      <InputMask
        mask="99999-999"
        name="zipCode"
        value={formik.values.zipCode}
        onChange={(event) => handleChangeCep(event.target.value)}>
        {() => (
          <TextField
            id="zipCode"
            name="zipCode"
            label="CEP *"
            variant="outlined"
            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
            helperText={formik.touched.zipCode && formik.errors.zipCode}
          />
        )}
      </InputMask>
    </>
  );
}
