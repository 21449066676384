import moment from "moment";
import { formataCpfCnpj } from "./document";

export function formatMonetario(valor) {
  valor = valor || 0;
  if (typeof valor == "string") {
    valor = parseFloat(valor);
  }
  return valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
}
export function apenasNumeros(str) {
  return (str || "").toString().replace(/\D/g, "");
}
export function apenasLetrasNumeros(str) {
  return (str || "").toString().replace(/[^a-zA-Z0-9]/g, "");
}
export function apenasMonetario(str) {
  return (str || "").toString().replace(/[^01234567890,.]/g, "");
}

export function aoPressionarEnter(event, executar) {
  if (event.key === "Enter") {
    executar();
  }
}

export const formatPhoneNumber = (valor) => {
  if (!valor) return "";

  const numero = valor.replace(/[^\d]/g, "");

  if (numero.length === 11) {
    return `(${numero.substring(0, 2)}) ${numero.substring(
      2,
      7
    )}-${numero.substring(7)}`;
  } else if (numero.length === 10) {
    return `(${numero.substring(0, 2)}) ${numero.substring(
      2,
      6
    )}-${numero.substring(6)}`;
  } else {
    return valor;
  }
};

export const getRandomString = (size) => {
  var string = "";
  var caracteres =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < size; i++) {
    string += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return string;
};

export async function sleep(msec) {
  return new Promise((resolve) => setTimeout(resolve, msec));
}

export function validaNumeroTelefone(telefone) {
  const regexTelefone = /^\(\d{2}\) \d{5}-\d{4}$/;
  return regexTelefone.test(telefone);
}

export function validarEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const formataTipoChave = (chave) => {
  let chaveFormatada = "";

  switch (chave) {
    case "CpfCnpj":
      chaveFormatada = "CPF/CNPJ";
      break;
    case "Email":
      chaveFormatada = "E-mail";
      break;

    default:
      chaveFormatada = chave;
      break;
  }

  return chaveFormatada;
};
export const formataChave = (chave, tipo) => {
  let chaveFormatada = "";

  switch (tipo) {
    case "CpfCnpj":
      chaveFormatada = formataCpfCnpj(chave);
      break;
    case "Telefone":
      chaveFormatada = formatPhoneNumber(chave);
      break;

    default:
      chaveFormatada = chave;
      break;
  }

  return chaveFormatada;
};

export function estaDentroDoPeriodoDeDias(data_inicial, data_final, qtd_dias) {
  const diferencaDias = moment(data_final).diff(data_inicial, "days");

  return diferencaDias <= qtd_dias;
}
