// material-ui
import { createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { getPrimaryColorButton } from "@/services/whitelabel";

const Palette = (mode) => {
  const user = useSelector((state) => state.user);
  return createTheme({
    palette: {
      background: {
        default: "#fafaf3",
      },
      primary: {
        light: getPrimaryColorButton(user),
        main: getPrimaryColorButton(user),
        dark: getPrimaryColorButton(user),
        contrastText: "#ffffff",
      },
    },
  });
};

export default Palette;
