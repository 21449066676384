import moment from "moment";
import { post } from "./api";

export async function registerMetadata(data) {
  try {
    const lastRegisterDatetime = sessionStorage.getItem(
      "REGISTER_METADATA_DATETIME"
    );
    if (lastRegisterDatetime) {
      const diffSeconds = moment().diff(
        moment(lastRegisterDatetime),
        "seconds"
      );
      if (diffSeconds < 5) {
        return;
      }
    }
    const lastRegister = localStorage.getItem("REGISTER_METADATA");
    if (!data.utmCampaign && lastRegister && lastRegister.utmCampaign) {
      data.utmSource = lastRegister.utmSource;
      data.utmMedium = lastRegister.utmMedium;
      data.utmCampaign = lastRegister.utmCampaign;
      data.utmId = lastRegister.utmId;
      data.utmTerm = lastRegister.utmTerm;
    }
    localStorage.setItem("REGISTER_METADATA", data);
    const idMetadataMarketing = sessionStorage.getItem("idMetadataMarketing");
    data.idMetadataRef = idMetadataMarketing || "";
    const result = await post(`public/register-metadata`, data);
    sessionStorage.setItem("idMetadataMarketing", result._id);
    sessionStorage.setItem("REGISTER_METADATA_DATETIME", new Date());
  } catch (e) {
    console.log(e);
  }
}
