import axios from "axios";
import { ehHomologacao, ehProducao } from "./ambiente";

function retornaHeaders() {
  let usuarioLogado = {};
  try {
    const dadosUsuario = sessionStorage.getItem("dadosUsuarioLogado");
    if (!dadosUsuario || dadosUsuario === "null") {
      usuarioLogado = {};
    } else {
      usuarioLogado = JSON.parse(dadosUsuario);
    }
  } catch (e) {}
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (usuarioLogado.userToken || ""),
    },
  };
}

export function retornaUrlBase() {
  if (ehHomologacao()) {
    return "https://api.homologacao.quitaboletos-cp.com/";
  }
  if (ehProducao()) {
    return "https://api-personal-credit.quitaboletos.com/";
  }

  return "http://localhost:21099/";
}

function retornaUrlServer() {
  let url = retornaUrlBase();
  return url + "internal-client/";
}

const trataErro = (error, reject) => {
  if (error.response && error.response.status === 401) {
    return;
  }
  reject(error);
};

export function get(endpoint) {
  const promise = new Promise((resolve, reject) => {
    const url = retornaUrlServer() + endpoint;
    axios
      .get(url, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

export function post(endpoint, body) {
  const promise = new Promise((resolve, reject) => {
    if (!body) {
      body = {};
    }

    const url = retornaUrlServer() + endpoint;
    axios
      .post(url, body, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

export function getErrorApiMessage(e) {
  if (e.response?.data?.errors?.length > 0) {
    return e.response.data.errors[0].message;
  }
  return "Ocorreu um erro ao consultar os dados. Por favor atualize a página e tente novamente!";
}
