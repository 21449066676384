import { lazy } from "react";

// project import
import Loadable from "@/components/Loadable";
import MinimalLayoutParceiro from "@/layout/MinimalLayoutParceiro";
import { Navigate } from "react-router-dom";
const Login = Loadable(
  lazy(() => import("@/pages/parceiro/areaAberta/login/Login"))
);
const CadastroParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/areaAberta/cadastro-parceiro"))
);
const FinalizacaoCadastroParceiro = Loadable(
  lazy(() =>
    import("@/pages/parceiro/areaAberta/cadastro-parceiro/finalizacao")
  )
);
const SimuladorComissionamento = Loadable(
  lazy(() => import("@/pages/parceiro/areaAberta/simulador-comissionamento"))
);
const CadastroSenhaParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/areaAberta/cadastro-senha"))
);

const AcessoAdmin = Loadable(
  lazy(() => import("@/pages/parceiro/areaAberta/login/AcessoAdmin"))
);
const CadastroEmpresaParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/areaAberta/cadastro-empresa-parceiro"))
);
const ReponsavelEmpresa = Loadable(
  lazy(() =>
    import("@/pages/parceiro/areaAberta/cadastro-parceiro/ResponsavelEmpresa")
  )
);

const AreaAbertaRoutes = {
  path: "parceiro",
  element: <MinimalLayoutParceiro />,
  children: [
    {
      path: "",
      element: <Navigate {...{ to: "/parceiro/login" }} />,
    },
    {
      path: "login/:tipoLogin",
      element: <Login />,
    },
    {
      path: "acesso-admin/:company/:token",
      element: <AcessoAdmin />,
    },
    {
      path: "acesso-admin/:company/:token/:tela",
      element: <AcessoAdmin />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "login-token/:tipoLogin/:token",
      element: <Login />,
    },

    {
      path: "cadastro",
      element: <CadastroParceiro />,
    },
    {
      path: "cadastro-responsavel-empresa/:idCompany/:token",
      element: <ReponsavelEmpresa />,
    },
    {
      path: "finalizacao-cadastro",
      element: <FinalizacaoCadastroParceiro />,
    },
    {
      path: "confirmar-cadastro/:idParceiro/:token",
      element: <CadastroSenhaParceiro />,
    },
    {
      path: "simular-comissionamento",
      element: <SimuladorComissionamento />,
    },
    {
      path: "cadastro-parceiro/:idParceiro/:token",
      element: <CadastroEmpresaParceiro />,
    },

    {
      path: "*",
      element: <Navigate {...{ to: "/parceiro/login" }} />,
    },
  ],
};

export default AreaAbertaRoutes;
