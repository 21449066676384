import PersonIcon from "@mui/icons-material/Person";
import AddCardIcon from "@mui/icons-material/AddCard";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import DashboardIcon from "@mui/icons-material/Dashboard";

const pages = {
  id: "authentication",
  title: "",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "MEUS PEDIDOS",
      type: "item",
      url: "/app/area-cliente/pedidos",
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "simulacao",
      title: "CONTRATAR",
      type: "item",
      url: "/app/area-cliente/simulacao",
      icon: AddCardIcon,
      breadcrumbs: false,
    },

    {
      id: "meus-dados",
      title: "MEUS DADOS",
      type: "item",
      url: "/app/area-cliente/meus-dados",
      icon: PersonIcon,
      breadcrumbs: false,
    },
    {
      id: "alterar-senha",
      title: "ALTERAR SENHA",
      type: "item",
      url: "/app/area-cliente/alterar-senha",
      icon: VpnKeyIcon,
      breadcrumbs: false,
    },
  ],
};

export default pages;
