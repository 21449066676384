import { lazy } from "react";
import Loadable from "@/components/Loadable";

const Inicio = Loadable(
  lazy(() => import("@/pages/cliente/areaAberta/login/Inicio"))
);

const GeralRoutes = {
  path: "",
  children: [
    {
      path: "",
      element: <Inicio />,
    },

    {
      path: "*",
      element: <Inicio />,
    },
  ],
};

export default GeralRoutes;
