import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import { getTopBarColor } from "@/services/whitelabel";
import { useSelector } from "react-redux";

const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);

  return (
    <DrawerHeaderStyled
      style={{ backgroundColor: getTopBarColor(user) }}
      theme={theme}
      open={open}></DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
