import BlankLayout from "@/layout/BlankLayout";
import { lazy } from "react";
import Loadable from "@/components/Loadable";

const Biometria = Loadable(
  lazy(() => import("@/pages/common/biometria/Biometry"))
);
const BiometriaCam = Loadable(
  lazy(() => import("@/pages/common/biometria/biometryCam/BiometryCam"))
);

const AreaAbertaRoutes = {
  path: "biometria",
  element: <BlankLayout />,
  children: [
    {
      path: ":idRequest/:tokenRequest/:idUser/:token",
      element: <Biometria />,
    },
    {
      path: "camera/:idRequest/:tokenRequest/:idUser/:token",
      element: <BiometriaCam />,
    },
  ],
};

export default AreaAbertaRoutes;
