import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reloadWarnings: false,
};

const warnings = createSlice({
  name: "warnings",
  initialState,
  reducers: {
    setReloadWarnings(state, action) {
      state.reloadWarnings = action.payload;
    },
  },
});

export default warnings.reducer;
export const { setReloadWarnings } = warnings.actions;
