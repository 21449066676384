import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const GoogleRecaptcha = (props) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lc5yWgjAAAAANZXN6dF7fci68cey63-dlIf6kcd">
      {props.children}
    </GoogleReCaptchaProvider>
  );
};

export default GoogleRecaptcha;
