import DashboardIcon from "@mui/icons-material/Dashboard";
import GppGoodIcon from "@mui/icons-material/GppGood";
import GroupIcon from "@mui/icons-material/Group";
import BusinessIcon from "@mui/icons-material/Business";
import ReorderIcon from "@mui/icons-material/Reorder";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const dashboard = {
  id: "group-dashboard",
  title: "",
  type: "group",
  url: "/dashboard",
  children: [
    {
      id: "dashboard",
      title: "RESUMO",
      type: "item",
      url: "",
      icon: DashboardIcon,
      breadcrumbs: false,
    },

    {
      id: "operacoes",
      title: "OPERAÇÕES",
      type: "item",
      url: "operacoes",
      icon: ReorderIcon,
      breadcrumbs: false,
    },

    {
      id: "comissoes",
      title: "COMISSIONAMENTO",
      type: "item",
      url: "comissoes",
      icon: CurrencyExchangeIcon,
      breadcrumbs: false,
      permissao: "visualizar-comissoes",
    },
    {
      id: "comissoes-2",
      title: "COMISSÕES/SAQUE",
      type: "item",
      url: "comissoes-gerais",
      icon: CurrencyExchangeIcon,
      breadcrumbs: false,
      permissao: "visualizar-comissoes-2",
    },
    {
      id: "indicacao",
      title: "INDIQUE E GANHE",
      type: "item",
      url: "indique-e-ganhe",
      icon: GroupAddIcon,
      breadcrumbs: false,
    },
    {
      id: "clientes",
      title: "CLIENTES",
      type: "item",
      url: "clientes",
      icon: GroupIcon,
      breadcrumbs: false,
      permissao: "visualizar-clientes",
    },
    {
      id: "controle-acesso",
      title: "CONTROLE DE ACESSO",
      type: "item",
      url: "controle-acesso",
      icon: GppGoodIcon,
      breadcrumbs: false,
      permissao: "controle-acesso",
    },
    {
      id: "parceiros",
      title: "EMPRESAS",
      type: "item",
      url: "parceiros",
      icon: BusinessIcon,
      breadcrumbs: false,
      permissao: "visualizar-empresas",
    },

    {
      id: "configuracoes",
      title: "CONFIGURAÇÕES",
      type: "item",
      url: "configuracoes",
      icon: SettingsIcon,
      breadcrumbs: false,
      permissao: "system-config",
    },
    {
      id: "como-funciona",
      title: "COMO FUNCIONA",
      type: "item",
      url: "como-funciona",
      icon: HelpOutlineIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
