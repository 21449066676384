export default function IconSad(props) {
  return (
    <svg
      fill="#000000"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 53.938 53.938"
      {...props}>
      <path d="M15.217,40.079c-0.492,0-0.983-0.181-1.367-0.55c-0.786-0.754-0.813-2.002-0.058-2.787 c3.611-3.766,8.471-5.84,13.687-5.84c4.629,0,9.084,1.687,12.549,4.748c0.816,0.722,0.894,1.968,0.172,2.783 c-0.724,0.819-1.969,0.896-2.787,0.174c-2.741-2.424-6.27-3.758-9.934-3.758c-4.129,0-7.979,1.644-10.838,4.621 C16.253,39.876,15.735,40.079,15.217,40.079z"></path>{" "}
      <circle cx="20.872" cy="24.363" r="2.604"></circle>{" "}
      <circle cx="33.067" cy="24.363" r="2.604"></circle>
    </svg>
  );
}
