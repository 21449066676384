import * as React from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ehHomologacao } from "../../services/ambiente";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LogoFundoEscuro from "../LogoFundoEscuro";
import { getLightColorButton, getTopBarColor } from "../../services/whitelabel";
import BotaoSecundario from "../BotaoSecundario";
import "./styles.scss";

const ResponsiveAppBar = (params: any) => {
  const location = useLocation();
  const navigation = useNavigate();
  const user = useSelector((state: any) => state.user);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const ehTelaLogin = () => {
    return location.pathname.indexOf("login") !== -1;
  };

  return (
    <AppBar style={{ backgroundColor: getTopBarColor(user) }} position="static">
      <Container
        style={{ backgroundColor: getTopBarColor(user) }}
        maxWidth="xl">
        <Toolbar
          style={{ backgroundColor: getTopBarColor(user) }}
          disableGutters>
          <Typography
            variant="h6"
            noWrap
            onClick={() => {
              navigation("/app/simulacao");
            }}
            style={{ color: "white" }}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              textDecoration: "none",
            }}>
            {!isMobile && <LogoFundoEscuro />}

            {ehHomologacao() ? (
              <span style={{ paddingTop: "15px" }}>HOMOLOGAÇÃO</span>
            ) : (
              ""
            )}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              style={{ color: "white" }}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}>
              <MenuItem
                onClick={() => {
                  navigation("/app/login");
                }}>
                <Typography textAlign="center">LOGIN</Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}>
            {!isMobile && <LogoFundoEscuro />}
          </Typography>

          {!ehTelaLogin() && isMobile && !user.isQuitaboletos && (
            <BotaoSecundario
              className="header-item-entrar"
              style={{ color: getLightColorButton(user) }}
              onClick={() => {
                window.open(
                  `https://api.whatsapp.com/send?phone=55${user.whatsappSuport}&text=Ol%C3%A1. Preciso de ajuda com o portal!`
                );
              }}
              sx={{ ml: 2, color: "white" }}
              startIcon={<WhatsAppIcon />}>
              Atendimento
            </BotaoSecundario>
          )}

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}>
            {!ehTelaLogin() && !user.isQuitaboletos && (
              <BotaoSecundario
                className="header-item header-item-entrar"
                style={{ color: getLightColorButton(user) }}
                onClick={() => {
                  window.open(
                    `https://api.whatsapp.com/send?phone=55${user.whatsappSuport}&text=Ol%C3%A1. Preciso de ajuda com o portal!`
                  );
                }}
                sx={{ m: 2, color: "white" }}
                startIcon={<WhatsAppIcon />}>
                Atendimento
              </BotaoSecundario>
            )}
            {!ehTelaLogin() && (
              <BotaoSecundario
                className="header-item header-item-entrar"
                style={{ color: getLightColorButton(user) }}
                onClick={() => {
                  navigation("/app/login");
                }}
                sx={{ my: 2, ml: 1, color: "white", display: "block" }}>
                Login
              </BotaoSecundario>
            )}
            {ehTelaLogin() && (
              <BotaoSecundario
                className="header-item header-item-entrar"
                style={{ color: getLightColorButton(user) }}
                onClick={() => {
                  navigation("/parceiro/login");
                }}
                sx={{ m: 2, color: "white", display: "block" }}>
                Área do parceiro
              </BotaoSecundario>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
