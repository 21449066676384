import { getRandomString } from "./utils";
import { v4 as uuidv4 } from "uuid";

export function generateDeviceId() {
  if (!localStorage.getItem("deviceIdQuitaBoletos")) {
    let deviceId = new Date().getTime();
    deviceId += "_";
    deviceId += getRandomString(80);
    deviceId += "_";
    deviceId += uuidv4();
    localStorage.setItem("deviceIdQuitaBoletos", deviceId);
  }
}
