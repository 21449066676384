// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import user from "./user";
import geral from "./geral";
import biometria from "./biometria";
import dashboardParceiro from "./dashboardParceiro";
import warnings from "./warnings";
import creditoPessoal from "./creditoPessoal";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  user,
  dashboardParceiro,
  geral,
  biometria,
  warnings,
  creditoPessoal,
});

export default reducers;
