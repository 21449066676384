import { lazy } from "react";

import Loadable from "@/components/Loadable";
import MinimalLayout from "../layout/MinimalLayout";
import { Navigate } from "react-router-dom";
import AguardandoAnalise from "../pages/cliente/areaAberta/credito-pessoal/analise-credito/AguardandoAnalise";
import PreAnaliseDeCreditoComCadastro from "../pages/cliente/areaAberta/credito-pessoal/pre-analise-com-cadastro";
import PreAnaliseFinalizada from "../pages/cliente/areaAberta/credito-pessoal/analise-credito/PreAnaliseFinalizada";

const CreditoPessoal = Loadable(
  lazy(() => import("@/pages/cliente/areaAberta/credito-pessoal"))
);
const AnaliseDeCredito = Loadable(
  lazy(() =>
    import("@/pages/cliente/areaAberta/credito-pessoal/analise-credito")
  )
);
const SimulacaoCreditoPessoal = Loadable(
  lazy(() => import("@/pages/cliente/areaAberta/credito-pessoal/simulacao"))
);
const AnaliseEnviada = Loadable(
  lazy(() =>
    import(
      "@/pages/cliente/areaAberta/credito-pessoal/analise-credito/AnaliseEnviada"
    )
  )
);

const CreditoPessoalRoutes = {
  path: "app/credito-pessoal",
  element: <MinimalLayout />,
  children: [
    {
      path: "",
      element: <Navigate {...{ to: "/app/credito-pessoal/inicio" }} />,
    },
    {
      path: "inicio",
      element: <CreditoPessoal />,
    },
    {
      path: "analise/:id/:token",
      element: <AnaliseDeCredito />,
    },
    {
      path: "pre-analise/:id/:token",
      element: <PreAnaliseDeCreditoComCadastro />,
    },
    {
      path: "analise-enviada/:id/:token",
      element: <AnaliseEnviada />,
    },
    {
      path: "pre-analise-finalizada/:id/:token",
      element: <PreAnaliseFinalizada />,
    },

    {
      path: "aguardando-analise/:id/:token",
      element: <AguardandoAnalise />,
    },

    {
      path: "simulacao/:id/:token",
      element: <SimulacaoCreditoPessoal />,
    },
    {
      path: "*",
      element: <CreditoPessoal />,
    },
  ],
};

export default CreditoPessoalRoutes;
